const modals = ref<string[]>([])
const persistentModals = ref<string[]>([])

function isCryptoIsAvailible() {
  return !!crypto?.randomUUID
}

function generateUUID() {
  const array = new Uint8Array(16)
  crypto.getRandomValues(array)

  array[6] = (array[6] & 0x0f) | 0x40
  array[8] = (array[8] & 0x3f) | 0x80

  return [...array]
    .map((b, i) => (i === 4 || i === 6 || i === 8 || i === 10 ? '-' : '') + b.toString(16).padStart(2, '0'))
    .join('')
}

function generateModalName() {
  const prefix = 'modal_'

  if (isCryptoIsAvailible) {
    return prefix + crypto.randomUUID()
  }

  return prefix + generateUUID()
}

function openModal(name: string, keepOpen = false): void {
  modals.value.push(name)
  if (keepOpen) {
    markModalAsPersistent(name)
  }
}

function markModalAsPersistent(name: string): void {
  persistentModals.value.push(name)
}

function closeModal(name: string): void {
  modals.value = modals.value.filter((modal) => modal !== name)
  unmarkModalAsPersistent(name)
}

function unmarkModalAsPersistent(name: string): void {
  persistentModals.value = persistentModals.value.filter((modal) => modal !== name)
}

function isModalOpen(name: string): boolean {
  return modals.value.includes(name)
}

function getLastModal(): string | undefined {
  return modals.value[modals.value.length - 1]
}

function closeAllModals(): void {
  modals.value = [...persistentModals.value]
}

export function useModalState() {
  return {
    openModal,
    closeModal,
    isModalOpen,
    getLastModal,
    closeAllModals,
    generateModalName,
    markModalAsPersistent,
    unmarkModalAsPersistent,
  }
}
